<template>
  <v-row>
    <v-col cols="7">
      <vx-card title="Featured specs">
        <div v-if="filters.length > 0">
          <v-data-table
            :headers="headers"
            :hide-default-footer="true"
            :items="filters"
          >
            <template v-slot:body="props">
              <draggable v-model="filters" tag="tbody">
                <tr v-for="item in filters" :key="item.id" class="custom-tr children cursor-move">
                  <td style="width: 65px">
                    <vs-icon icon="fa-bars" icon-pack="fal"></vs-icon>
                  </td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-right" style="width: 100px">
                    <delete-button without-confirmation @delete="deleteItem(item)"></delete-button>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </div>
        <div v-else> Nu este nici un spec atribuit</div>
        <div class="mt-6">
          <vs-button color="primary" class="primary-button" type="filled" @click="saveFilters">Save</vs-button>
        </div>
      </vx-card>
    </v-col>
    <v-col cols="5">
      <vx-card title="Adauga featured specs">
        <featured-specs-filters-component-type-select :category-id="currentCategoryId"/>
      </vx-card>
    </v-col>
  </v-row>
</template>
<script>
import draggable from 'vuedraggable'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FeaturedSpecsFiltersComponentTypeSelect from './components/FeaturedSpecsFiltersComponentTypeSelect'

export default {
  name: 'CategoryFilters',
  components: {
    FeaturedSpecsFiltersComponentTypeSelect,
    DeleteButton,
    EditButton,
    draggable
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'handler',
          width: '65',
          sortable: false
        },
        {
          text: 'Tip',
          value: 'type',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  computed: {
    currentCategoryId () {
      return this.$route.params.id
    },
    filters: {
      get () {
        return this.$store.getters['featuredSpecsFilters/filters']
      },
      set (value) {
        this.$store.commit('featuredSpecsFilters/SET_FILTERS', value)
      }
    }
  },
  methods: {
    saveFilters () {
      this.$store.dispatch('startLoading')
      this.$store.dispatch('featuredSpecsFilters/saveItems')
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Filtrele au fost salvate!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    deleteItem (item) {
      this.$store.commit('featuredSpecsFilters/DELETE_ITEM', item.id)
    }
  },
  mounted () {
    this.$store.commit('featuredSpecsFilters/SET_CATEGORY_ID', this.currentCategoryId)
    this.$store.dispatch('featuredSpecsFilters/loadItems')

  }
}
</script>
<style scoped>
</style>
